import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Button from "./Button"

import { container, imageContainer, textContainer, descriptionParagraph } from "./blogArticleCard.module.css"

const BlogArticleCard = ({ title, date, description, slug, image }) => {
  return <div className={`${container} flex flex-col pb-4 rounded-lg border-yellow border-t-8`}>
    <GatsbyImage
      image={image}
      alt={`Cover for ${title}`}
      className={imageContainer}
    />

    <div className={`${textContainer} flex-grow px-2 overflow-y-hidden`}>
      <h3>{title}</h3>
      <span className="text-sm">{date}</span>
      <p className={descriptionParagraph}>{description}</p>
    </div>

    <div className="text-right pt-2 pr-2">
      <Button to={`/blog/${slug}`} text="Leggi" />
    </div>
  </div>;
};

export default BlogArticleCard;
