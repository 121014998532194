import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Hero from "../../components/Hero"
import BlogArticleCard from "../../components/BlogArticleCard"

const query = graphql`
{
  allFile(
    filter: {sourceInstanceName: {eq: "blog"}}
    sort: {order: DESC, fields: childrenMarkdownRemark___frontmatter___date}
  ) {
    nodes {
      childMarkdownRemark {
        frontmatter {
          slug
          title
          description
          date(formatString: "YYYY-MM-DD")
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`;

const BlogPage = () => {
  const { allFile: { nodes: allBlogArticles } } = useStaticQuery(query);

  return <Layout>
    <Seo title="Blog" />
    <Hero title="Blog" />

    <section className="grid grid-cols-6 my-8">
      <div className="col-span-6 md:col-span-4 md:col-start-2 grid grid-cols-2 gap-y-8">
        {allBlogArticles.map((article, index) => {
          const {
            image,
            date,
            title,
            slug,
            description,
          } = article.childMarkdownRemark.frontmatter;

          return <article key={index} className="col-span-2 md:col-span-1 px-5">
            <BlogArticleCard
              title={title}
              date={date}
              description={description}
              slug={slug}
              image={image.childImageSharp.gatsbyImageData}
            />
          </article>;
        })}
      </div>
    </section>

  </Layout>;
};

export default BlogPage;
